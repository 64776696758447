<template>
  <b-col lg="12">
    <b-form-group
      :label="label"
      label-cols-md="4"
    >
      <v-select
        v-model="selected"
        multiple
        label="title"
        :options="option"
      />
    </b-form-group>
  </b-col>
</template>

<script>
import {
  BCol, BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BCol,
    BFormGroup,
    vSelect,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      selected: [],
      options: [],
      option: [
        { title: 'Square' },
        { title: 'Rectangle' },
        { title: 'Rombo' },
        { title: 'Romboid' },
        { title: 'Trapeze' },
        { title: 'Triangle' },
        { title: 'Polygon' },
      ],
    }
  },
}
</script>
