<template>
  <div
    v-if="value"
  >
    <div class="ml-1">
      {{ label }}
    </div>
    <div
      v-for="(item, index) in titleList"
      :key="index"
      class="mt-1 ml-2"
    >
      <BasicCheckBox
        :label="item"
        :select="choiceData[item]"
        :value="value[item]"
        @childData="input(item,$event)"
      />
    </div>
  </div>
</template>

<script>
import BasicCheckBox from '@/components/ui/form/checkbox/BasicCheckBox.vue'

export default {
  components: {
    BasicCheckBox,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    value: {
      type: Object,
      default: () => {},
    },
    select: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selected: {},
      titleList: [],
      choiceData: {},
      inputForm: {},
    }
  },
  watch: {
    select: {
      handler() {
        if (this.select !== []) {
          const listA = []
          window.console.log('🏎✨️', this.select)
          /* eslint-disable */
          for (let item in this.select) {
            const label = this.select[item]
            listA.push(label.category)
          }
          window.console.log('🌻', listA)
          this.titleList = Array.from(new Set(listA))
          window.console.log('🌺', this.titleList)
          for (let item in this.titleList) {
            const category = this.titleList[item]
            const dic = this.select.filter(v => v.category === category)
            this.selected[category] = []
            this.choiceData[category] = dic
          }
          window.console.log('🍎', this.choiceData)
         /* eslint-enable  */
        }
      },
      immediate: true,
    },
    value: {
      handler() {
        // 編集する際に既にデータが登録されている場合それを反映する
        if (this.value !== {}) {
          this.inputForm = this.value
        }
      },
      immediate: true,
    },
  },
  mounted() {
  },
  methods: {
    send() {
      this.$emit('childData', this.inputForm)
      window.console.log('🏠', this.inputForm)
    },
    input(item, val) {
      // window.console.log('🌻', item, val)
      this.inputForm[item] = val
      // window.console.log('🏠🏠', this.inputForm)
      this.$emit('childData', this.inputForm)
    },
  },
}
</script>
