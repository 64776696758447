<template>
  <div>
    <div class="ml-1">
      {{ label }}
    </div>
    <div
      class="ml-2"
    >
      <div class="demo-inline-spacing mb-1">
        <b-form-checkbox
          v-for="(it, index) in select"
          :key="index"
          v-model="selected"
          :value="it.num"
          @input="send"
        >
          {{ it.lessonLabel }}
        </b-form-checkbox>
      </div>
    </div>
  </div>
</template>

<script>
import { BFormCheckbox } from 'bootstrap-vue'

export default {
  components: {
    BFormCheckbox,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    value: {
      type: Array,
      default: () => [],
    },
    select: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selected: [],
      titleList: [],
      choiceData: {},
    }
  },
  watch: {
    value: {
      handler() {
        // 編集する際に既にデータが登録されている場合それを反映する
        if (this.value !== []) {
          this.selected = this.value
        }
      },
      immediate: true,
    },
  },
  methods: {
    send() {
      // this.selected[item].push(num)
      // window.console.log('🚀', this.selected)
      this.$emit('childData', this.selected)
    },
  },
}
</script>
