<template>
  <b-col cols="12">
    <b-form-group
      :label="label"
      label-cols-md="4"
    >
      <v-select
        v-model="selected"
        label="labelName"
        :options="choice"
        @input="send"
      />
    </b-form-group>
    <b-alert
      variant="primary"
      show
    >
      <div class="alert-body">
        <small>
          <b>サービス方法について</b>
          <ul>
            <li>eラーニング：動画によるレッスンです。動画のURLを貼り付けてください。</li>
            <li>オンラインレッスン：双方向で話すことができます。</li>
            <li>オンラインセミナー：主催者側が用意する主催者による一方向の講義です。主催者からのみの話すことができます。</li>
            <li>オンラインイベント：インターネット上で主催者側が用意する主催者による複数開催のイベントです。主催者からのみ発信します。</li>
            <li>オフラインイベント：実際に現地で開催されるイベントです。</li>
          </ul>
        </small>
      </div>
    </b-alert>
  </b-col>
</template>

<script>
import {
  BCol, BFormGroup, BAlert,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
// import 'vue-select/dist/vue-select.css'

export default {
  components: {
    BCol,
    BFormGroup,
    vSelect,
    BAlert,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    value: {
      type: Object,
      default: () => {},
    },
    choice: {
      type: Array,
      default: () => [],
    },
    defaultValue: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selected: {},
    }
  },
  watch: {
    value: {
      handler() {
        // window.console.log('🚀 ', this.value, Object.keys(this.value).length)
        if (Object.keys(this.value).length !== 0) {
          this.selected = this.value
          return
        }
        /* eslint-disable prefer-destructuring */
        this.selected = this.defaultValue[0]
        this.send()
        /* eslint-enable */
        // window.console.log('🌺', this.selected, this.defaultValue)
      },
      immediate: true,
      deep: true,
    },
    // defaultValue: {
    //   handler() {
    //     if (this.defaultValue.length > 0 && Object.keys(this.value).length !== 0) {
    //       window.console.log('🐶🐶', this.defaultValue[0])
    //       /* eslint-disable prefer-destructuring */
    //       this.selected = this.defaultValue[0]
    //       this.send()
    //       /* eslint-enable */
    //     }
    //   },
    //   immediate: true,
    // },
    selected: {
      handler() {
        this.send()
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    send() {
      this.$emit('childData', this.selected)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
