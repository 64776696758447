<template>
  <div>
    <!-- <b-row>
      <b-col lg="4">
        <div class="ml-1">
          {{ label }}
        </div>
      </b-col>
      <b-col>
        <div class="demo-inline-spacing mb-1 ml-4">
          <b-form-checkbox
            v-for="(item, index) in titleList"
            :key="index"
            @input="send"
          >
            {{ item }}
          </b-form-checkbox>
        </div>
      </b-col>
    </b-row> -->
    <b-col cols="12">
      <b-form-group
        :label="label"
      >
        <div class="demo-inline-spacing mb-1 ml-4">
          <b-form-checkbox
            v-for="(item, index) in titleList"
            :key="index"
            @input="send"
          >
            {{ item }}
          </b-form-checkbox>
        </div>
      </b-form-group>
    </b-col>
  </div>
</template>

<script>
import {
  BFormCheckbox, BCol, BFormGroup,
} from 'bootstrap-vue'

export default {
  components: {
    BFormCheckbox,
    BCol,
    // BRow,
    BFormGroup,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    select: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selected: {},
      titleList: [],
      choiceData: {},
      inputForm: {},
    }
  },
  watch: {
    select: {
      handler() {
        if (this.select !== []) {
          const listA = []
          // window.console.log('🏎✨️', this.select)
          /* eslint-disable */
          for (let item in this.select) {
            const label = this.select[item]
            listA.push(label.choiceLabel)
          }
          this.titleList = Array.from(new Set(listA))
          // window.console.log('🌺', this.titleList)
         /* eslint-enable  */
        }
      },
      immediate: true,
    },
    value: {
      handler() {
        // 編集する際に既にデータが登録されている場合それを反映する
        if (this.value !== {}) {
          this.inputForm = this.value
        }
      },
      immediate: true,
    },
  },
  mounted() {
  },
  methods: {
    send() {
      this.$emit('childData', this.selected)
    },
    input(item, val) {
      // window.console.log('🌻', item, val)
      this.inputForm[item] = val
      // window.console.log('🏠🏠', this.inputForm)
      this.$emit('childData', this.inputForm)
    },
  },
}
</script>
